import { dbtTheme } from "@dbt/commons/configs/dbtTheme";
import { useBankId } from "hooks/bankid";
import { useAuth } from "hooks/contexts";
import { useCurrentBreakpoint, useMobileDevice, useUnmount } from "@dbt/commons/hooks";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BankIDTimeoutError } from "./BankIDTimeoutError";
import { BankIDGeneralError } from "./BankIDGeneralError";
import { BankIdAccessDeniedError } from "./BankIdAccessDeniedError";
import { BankIdEmailInUseError } from "./BankIdEmailInUseError";
import { PortalLoginFailure } from "./PortalLoginFailure";
import { BankIDLoading } from "./BankIDLoading";
import { BankIDInitialView } from "./BankIDInitialView";
import { QrComponent } from "./QrComponent";

// TODO: Handling desktop / mobile layout with different font sizes etc needs improvement.

export type BankIdFlowType = "login" | "signup";
export interface BankIDProps {
  isIframed: boolean;
  flowType: BankIdFlowType;
  signupOrgId?: string;
  signupEmail?: string;
  autoSignup?: boolean;
}
export const BankID = (props: BankIDProps) => {
  const {
    isIframed,
    flowType,
    signupOrgId,
    signupEmail,
    autoSignup
  } = props;
  const {
    currentBreakpointWidthAsInteger
  } = useCurrentBreakpoint();
  const {
    isMobileDevice
  } = useMobileDevice();
  const router = useRouter();
  const {
    login
  } = useAuth();
  const unmount$ = useUnmount();
  const isMobileBreakpoint = useMemo(() => {
    return currentBreakpointWidthAsInteger && currentBreakpointWidthAsInteger <= parseInt(dbtTheme.breakpoints.sm);
  }, [currentBreakpointWidthAsInteger]);
  const isMobile = isMobileBreakpoint || isMobileDevice;
  const autoStart = !isMobile;
  const [useThisDevice, setUseThisDevice] = useState<boolean | undefined>();
  const [startBankIdProcess, response, url, reset, cancelProcess] = useBankId({
    unmount$,
    flowType,
    signupOrgId,
    signupEmail,
    autoSignup,
    onComplete: login
  });
  const openLinkInParent = typeof window !== "undefined" && window === window.parent ? false : true;
  const sendUrlToParentWindow = useCallback(() => {
    parent.postMessage({
      bankIdRedirect: url
    }, "*");
  }, [url]);
  useEffect(() => {
    if (useThisDevice && url) {
      if (openLinkInParent) {
        sendUrlToParentWindow();
      } else {
        router.push(url);
      }
    }
  }, [router, url, useThisDevice, openLinkInParent, sendUrlToParentWindow]);
  const handleClick = () => {
    if (url) {
      if (openLinkInParent) {
        sendUrlToParentWindow();
      } else {
        router.push(url);
      }
    }
  };
  useEffect(() => {
    if (autoStart) {
      reset();
      startBankIdProcess();
    } else {
      cancelProcess();
      reset();
      setUseThisDevice(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoStart]);
  const handleRetry = useCallback(() => {
    reset();
    if (!isMobile) {
      startBankIdProcess();
    } else {
      setUseThisDevice(undefined);
    }
  }, [reset, isMobile, startBankIdProcess]);
  const startOnThisDevice = useCallback(() => {
    setUseThisDevice(true);
    if (useThisDevice === false) {
      reset();
    }
    startBankIdProcess();
  }, [useThisDevice, startBankIdProcess, reset]);
  const startOnOtherDevice = useCallback(() => {
    setUseThisDevice(false);
    startBankIdProcess();
  }, [startBankIdProcess]);
  const handleCancel = useCallback(() => {
    cancelProcess();
    if (isMobile) {
      setUseThisDevice(undefined);
    } else {
      startBankIdProcess();
      setUseThisDevice(undefined);
    }
  }, [cancelProcess, isMobile, startBankIdProcess]);
  const {
    status,
    qrData,
    hintCode
  } = response;
  const renderMobile = () => {
    switch (status) {
      case "complete":
        return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
      case "pending":
        switch (hintCode) {
          case "started":
          case "userSign":
            return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
          case "outstandingTransaction":
            if (useThisDevice === false) {
              return <QrComponent isIframed={isIframed} isMobile={isMobile} onClick={startOnThisDevice} qrData={qrData} />;
            } else {
              return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
            }
          default:
            if (useThisDevice) {
              return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
            } else {
              return <BankIDInitialView isIframed={isIframed} isMobile={isMobile} qrData={qrData} url={url} reset={reset} handleClick={handleClick} setUseThisDevice={setUseThisDevice} startOnThisDevice={startOnThisDevice} startOnOtherDevice={startOnOtherDevice} />;
            }
        }
      case "failed":
        switch (hintCode) {
          case "startFailed":
            return <BankIDTimeoutError isIframed={isIframed} onRetry={handleRetry} />;
          default:
            return <BankIDGeneralError isIframed={isIframed} onRetry={handleRetry} />;
        }
      case "login_denied":
        return <BankIdAccessDeniedError isIframed={isIframed} />;
      case "email_in_use":
        return <BankIdEmailInUseError isIframed={isIframed} />;
      case "login_failed":
        return <PortalLoginFailure isIframed={isIframed} onRetry={handleRetry} />;
    }
  };
  const renderDesktop = () => {
    switch (status) {
      case "complete":
        return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
      case "pending":
        switch (hintCode) {
          case "started":
          case "userSign":
            return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
          default:
            if (useThisDevice) {
              return <BankIDLoading isIframed={isIframed} onCancel={handleCancel} />;
            } else {
              return <BankIDInitialView isIframed={isIframed} isMobile={isMobile} qrData={qrData} url={url} reset={reset} handleClick={handleClick} setUseThisDevice={setUseThisDevice} startOnThisDevice={startOnThisDevice} startOnOtherDevice={startOnOtherDevice} />;
            }
        }
      case "failed":
        switch (hintCode) {
          case "startFailed":
            return <BankIDTimeoutError isIframed={isIframed} onRetry={handleRetry} />;
          default:
            return <BankIDGeneralError isIframed={isIframed} onRetry={handleRetry} />;
        }
      case "login_denied":
        return <BankIdAccessDeniedError isIframed={isIframed} />;
      case "email_in_use":
        return <BankIdEmailInUseError isIframed={isIframed} />;
      case "login_failed":
        return <PortalLoginFailure isIframed={isIframed} onRetry={handleRetry} />;
    }
  };
  return <>{isMobile ? renderMobile() : renderDesktop()}</>;
};