import _styled from "@emotion/styled";
import { Text } from "@dbt/commons/components/text";
import { BankIdIcon } from "@dbt/commons/components/icons/other";
import { Link } from "@dbt/commons/components/link";
import { QrCode } from "@dbt/commons/components/qrCode";
import { Box } from "@dbt/commons/components/box";
import { Button } from "@dbt/commons/components/button";
import { css, cx } from "@emotion/css";
import { useTranslation } from "next-i18next";
const ContentContainer = _styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const header = css`
  text-align: center;
  margin-bottom: 32px;
`;
const headerMobile = css`
  margin-bottom: 4px;
`;
const topTextContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  margin-bottom: 48px;
  white-space: pre-line;
`;
const topTextContainerMobile = css`
  margin-bottom: 40px;
`;
const bankidLoginStepsContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  margin-bottom: 32px;
`;
const bankidLoginStepsText = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: center;
`;
const bankIdQrCodeContainer = css`
  margin-bottom: 32px;
`;
const buttonContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 42px;
  & button {
    align-self: center;
    justify-content: center;
    width: 100%;
  }
`;
const textCenter = css`
  text-align: center;
`;
interface BankIDInitialViewProps {
  isIframed: boolean;
  isMobile: boolean;
  qrData: string | undefined;
  url: string | undefined;
  reset: () => void;
  handleClick: () => void;
  setUseThisDevice: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  startOnThisDevice: () => void;
  startOnOtherDevice: () => void;
}
export const BankIDInitialView = ({
  isIframed,
  isMobile,
  qrData,
  url,
  reset,
  handleClick,
  setUseThisDevice,
  startOnThisDevice,
  startOnOtherDevice
}: BankIDInitialViewProps) => {
  const {
    t
  } = useTranslation("BankID");
  if (isMobile) {
    return <ContentContainer data-testid="bankIdInitialViewMobileSection">
        {!isIframed && <Text className={cx(header, headerMobile)} as="h3" font={"title_condensed"} size={32}>
            {t("title")}
          </Text>}

        <Box className={cx(topTextContainer, topTextContainerMobile)}>
          <Text as="p">{t("logingTopText")}</Text>
        </Box>

        <Box className={cx(bankidLoginStepsContainer)}>
          <BankIdIcon width={55} height={52} />
        </Box>

        <Box className={cx(buttonContainer)}>
          <Button variant="primary" color="positive" size="md" onClick={startOnThisDevice} data-testid="startBankIdOnThisDeviceButton">
            {t("mobileUseThisDevice")}
          </Button>
          <Button variant="secondary" color="positive" size="md" data-cy="start-bankid" data-testid="startBankIdOnAnotherDeviceButton" onClick={startOnOtherDevice}>
            {t("mobileUseOtherDevice")}
          </Button>
        </Box>

        <Text size={14} className={cx(textCenter)}>
          {t("mobileLoginInformation")}
        </Text>
      </ContentContainer>;
  } else {
    return <ContentContainer data-testid="bankIdInitialViewDesktopSection">
        {!isIframed && <Text className={cx(header)} as="h3" font={"title_condensed"}>
            {t("title")}
          </Text>}

        <Box className={cx(topTextContainer)}>
          <Text as="p">{t("logingTopText")}</Text>
        </Box>

        <Box className={cx(bankidLoginStepsContainer)}>
          <BankIdIcon />
          <Box className={cx(bankidLoginStepsText)}>
            <Text>{t("stepOne")}</Text>
            <Text>{t("stepTwo")}</Text>
            <Text>{t("stepThree")}</Text>
          </Box>
        </Box>

        <Box className={cx(bankIdQrCodeContainer)} data-testid="qrCodeContainer">
          <QrCode qrData={qrData} />
        </Box>

        {url && <Link onClick={() => {
        reset();
        handleClick();
        setUseThisDevice(true);
      }} tabIndex={0} data-testid="useBankIdOnThisDeviceLink">
            {t("desktopUseThisDevice")}
          </Link>}
      </ContentContainer>;
  }
};