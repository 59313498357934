import { Button } from "@dbt/commons/components/button";
import { Text } from "@dbt/commons/components/text";
import { Box } from "@dbt/commons/components/box";
import { useRouter } from "next/router";
import { css, cx } from "@emotion/css";
import { externalPaths } from "constants/paths";
import { InfoBox } from "@dbt/commons/components/infobox";
import { useTranslation } from "next-i18next";
const wrapper = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
const center = css`
  text-align: center;
`;
const header = css`
  margin-bottom: 48px;
`;
const marginBottom = css`
  margin-bottom: 32px;
`;
interface BankIdEmailInUseErrorProps {
  isIframed: boolean;
}
export const BankIdEmailInUseError = ({
  isIframed
}: BankIdEmailInUseErrorProps) => {
  const router = useRouter();
  const email = router.query.email;
  const {
    t
  } = useTranslation("BankID");
  return <Box className={cx(wrapper)} data-testid="bankIdEmailInUseErrorSection">
      {!isIframed ? <>
          <Text className={cx(header, center)} as="h3" font={"title_condensed"}>
            {t("loginFailed")}
          </Text>
          <Text className={cx(center, marginBottom)} data-cy="BankIdEmailInUseError">
            {t("emailInUse", {
          email
        })}
          </Text>
        </> : <InfoBox className={cx(marginBottom)} variant="error">{`Inloggningen misslyckades. Det finns redan en användare med mailen ${email}.`}</InfoBox>}

      <Button variant="primary" color="positive" size="md" className={cx(marginBottom)} onClick={() => location.assign(externalPaths.contact)} data-testid="contactUsButton">
        {t("contactDBT")}
      </Button>
    </Box>;
};