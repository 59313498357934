import _styled from "@emotion/styled";
import { Loading } from "@dbt/commons/components/loading";
import { Text } from "@dbt/commons/components/text";
import { Button } from "@dbt/commons/components/button";
import { css, cx } from "@emotion/css";
import { useTranslation } from "next-i18next";
const LoadingOuterContainer = _styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const LoadingContainer = _styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;
const marginBottomHeader = css`
  margin-bottom: 48px;
  text-align: center;
`;
const marginLoading = css`
  margin-bottom: 24px;
`;
const button = css`
  align-self: center;
`;
export const BankIDLoading = ({
  isIframed,
  onCancel
}: {
  isIframed: boolean;
  onCancel(): void;
}) => {
  const {
    t
  } = useTranslation("BankID");
  return <LoadingOuterContainer data-testid="bankIdLoadingSection">
      <LoadingContainer>
        {!isIframed && <Text className={cx(marginBottomHeader)} as="h3" font={"title_condensed"}>
            {t("title")}
          </Text>}
        <Loading className={cx(marginLoading)} size={64} />
        <Text>{t("identifyInApp")}</Text>
      </LoadingContainer>
      <Button variant="tertiary" color="positive" size="md" underlined className={cx(button)} onClick={onCancel} data-testid="abortLoadingButton">
        {t("abortLogin")}
      </Button>
    </LoadingOuterContainer>;
};