import _styled from "@emotion/styled";
import React from "react";
import { QrCode } from "@dbt/commons/components/qrCode";
import { Text } from "@dbt/commons/components/text";
import { Link } from "@dbt/commons/components/link";
import { Box } from "@dbt/commons/components/box";
import { css, cx } from "@emotion/css";
import { useTranslation } from "next-i18next";
const Container = _styled.div({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "space-evenly",
  "paddingBottom": "60px"
});
const header = css`
  text-align: center;
  font-size: 32px !important;
  margin-bottom: 32px;
`;
const stepContainer = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  margin: 20px 0px;
`;
export const QrComponent = ({
  isIframed,
  qrData,
  onClick,
  isMobile
}: {
  isIframed: boolean;
  qrData?: string;
  onClick(): void;
  isMobile: boolean | 0 | null;
}) => {
  const {
    t
  } = useTranslation("BankID");
  return <Container data-testid="bankIdQrOnMobileSection">
      <div>
        {!isIframed && <Text className={isMobile ? cx(header) : ""} as="h3" font={"title_condensed"}>
            {t("title")}
          </Text>}
        <Box data-testid="qrCodeContainer">
          <QrCode qrData={qrData} />
        </Box>
        <Box className={cx(stepContainer)}>
          <Text>{t("stepOne")}</Text>
          <Text>{t("stepTwo")}</Text>
          <Text>{t("stepThree")}</Text>
        </Box>
      </div>
      <Link className="text-center" variant={"dark"} href={"#"} onClick={onClick} tabIndex={0} data-testid="useBankIdOnThisDeviceLink">
        {t("desktopUseThisDevice")}
      </Link>
    </Container>;
};