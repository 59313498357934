import _styled from "@emotion/styled";
import { Text } from "@dbt/commons/components/text";
import { Button } from "@dbt/commons/components/button";
import { Box } from "@dbt/commons/components/box";
import { css, cx } from "@emotion/css";
import { Link } from "@dbt/commons/components/link";
import { externalPaths } from "constants/paths";
import { InfoBox } from "@dbt/commons/components/infobox";
import { useTranslation } from "next-i18next";
const center = css`
  text-align: center;
`;
const header = css`
  margin-bottom: 48px;
`;
const marginBottom = css`
  margin-bottom: 32px;
`;
const ContactInfo = _styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;
interface BankIdAccessDeniedErrorProp {
  isIframed: boolean;
}
export const BankIdAccessDeniedError = ({
  isIframed
}: BankIdAccessDeniedErrorProp) => {
  const {
    t
  } = useTranslation(["BankID", "Common"]);
  return <Box className={cx(center)} data-testid="bankIdAccessDeniedErrorSection">
      {!isIframed ? <>
          <Text className={cx(header, center)} as="h3" font={"title_condensed"}>
            {t("BankID:loginFailed")}
          </Text>
          <Text className={cx(center)} data-cy="BankIdAccessDeniedError">
            {t("BankID:noAccountInfo")}
          </Text>
        </> : <InfoBox variant="error" data-cy="BankIdAccessDeniedError">
          {t("BankID:noAccountInfo")}
        </InfoBox>}

      <ContactInfo className={cx(center, marginBottom)}>
        <span>
          {t("Common:call")}{" "}
          <Link href={`tel:${t("Common:dbtPhone")}`} data-testid="phoneLink">
            {t("Common:dbtPhone")}
          </Link>
        </span>
        <span>
          {t("Common:sendEmail")}{" "}
          <Link href={`mailto:${t("Common:dbtEmail")}`} data-testid="emailLink">
            {t("Common:dbtEmail")}
          </Link>
        </span>
      </ContactInfo>
      <Button variant="primary" color="positive" size="md" className={cx(marginBottom)} onClick={() => location.assign(externalPaths.dbt)} data-testid="gotoHomePageButton">
        {t("BankID:toStartPage")}
      </Button>
    </Box>;
};