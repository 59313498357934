import React from "react";
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from "../../types/polymorphic";
interface Props {
  children?: React.ReactNode;
}
type BoxProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;
type BoxComponent = <C extends React.ElementType = "div">(props: BoxProps<C>) => React.ReactElement | null;
export const Box: BoxComponent = React.forwardRef(<C extends React.ElementType = "div",>({
  as,
  children,
  ...rest
}: BoxProps<C>, ref?: PolymorphicRef<C>) => {
  const Component = as || "div";
  return <Component {...rest} ref={ref}>
        {children}
      </Component>;
});