import _styled from "@emotion/styled";
import { Text } from "@dbt/commons/components/text";
import { Button } from "@dbt/commons/components/button";
import { Box } from "@dbt/commons/components/box";
import { css, cx } from "@emotion/css";
import { Link } from "@dbt/commons/components/link";
import { InfoBox } from "@dbt/commons/components/infobox";
import { useTranslation } from "next-i18next";
const wrapper = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
const center = css`
  text-align: center;
  white-space: pre-line;
`;
const header = css`
  margin-bottom: 48px;
`;
const marginBottom = css`
  margin-bottom: 32px;
`;
const ContactInfo = _styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;
interface PortalLoginFailureProp {
  isIframed: boolean;
  onRetry(): void;
}
export const PortalLoginFailure = ({
  isIframed,
  onRetry
}: PortalLoginFailureProp) => {
  const {
    t
  } = useTranslation(["BankID", "Common"]);
  return <Box className={cx(wrapper)} data-testid="portalLoginFailureSection">
      {!isIframed ? <>
          <Text className={cx(header, center)} as="h3" font={"title_condensed"}>
            {t("BankID:loginFailed")}
          </Text>
          <Text className={cx(center)} data-cy="PortalLoginFailure">
            {t("BankID:loginFailureInfo")}
          </Text>
        </> : <InfoBox className={cx(marginBottom)} variant="error" data-cy="PortalLoginFailure">
          {t("BankID:loginFailureInfo")}
        </InfoBox>}

      <ContactInfo className={cx(center, marginBottom)}>
        <span>
          {t("Common:call")}{" "}
          <Link href={`tel:${t("Common:dbtPhone")}`} data-testid="phoneLink">
            {t("Common:dbtPhone")}
          </Link>
        </span>
        <span>
          {t("Common:sendEmail")}{" "}
          <Link href={`mailto:${t("Common:dbtEmail")}`} data-testid="emailLink">
            {t("Common:dbtEmail")}
          </Link>
        </span>
      </ContactInfo>
      <Button variant="primary" color="positive" size="md" className={cx(marginBottom)} onClick={onRetry} tabIndex={0} data-testid="tryAgainButton">
        {t("tryAgain")}
      </Button>
    </Box>;
};