import { UnmountHookReturnType } from "@dbt/commons/hooks";
import { authenticate, collect, cancel } from "api/client/bankid";
import { CollectResponse, ErrorResponse } from "pages/api/bankid/collect";
import { useCallback, useRef, useState } from "react";
import { defer, from, Subscription, switchMap, takeUntil, takeWhile, repeat } from "rxjs";
import { BankIdFlowType } from "components/bankid/BankID";
import log from "@dbt-capital/log";
//import { getMobileOperatingSystem } from "utils/getMobileOS";

const DELAY = 2000;
const initialResponse: CollectResponse & {
  orderRef?: string;
} = {
  status: "pending",
  qrData: "No Data"
};
const postLoginStatus = (status: boolean) => {
  window.parent?.postMessage({
    loggedIn: status
  }, {
    targetOrigin: "*"
  });
};
export function useBankId({
  unmount$,
  flowType,
  signupEmail,
  signupOrgId,
  autoSignup,
  onComplete
}: {
  unmount$: UnmountHookReturnType;
  flowType: BankIdFlowType;
  signupOrgId?: string;
  signupEmail?: string;
  autoSignup?: boolean;
  onComplete(): void;
}) {
  const subscription = useRef<Subscription>();
  const [response, setResponse] = useState(initialResponse);
  const [url, setUrl] = useState<string | undefined>();
  // const [host, setHost] = useState("");
  // const mobileOS = getMobileOperatingSystem();

  // The purpose of this useEffect is to set a host if bankID is opened with an iOS device
  // AND depending on if it is in iframe or not,
  // set the current window location or the parent window as host string
  // if not an iOS decive, BankID redirect is empty.
  // docs: https://www.bankid.com/assets/bankid/rp/bankid-relying-party-guidelines-v3.6.pdf (page 8 / keyword: "redirect")
  // useEffect(() => {
  //   // Get parent window url if bankID is iframed
  //   const url =
  //     window.location !== window.parent.location
  //       ? document.referrer
  //       : window.location.origin;

  //   if (mobileOS === "iOS") {
  //     setHost(url);
  //   }
  // }, [mobileOS]);

  const cancelProcess = useCallback(async () => {
    if (subscription.current) {
      subscription.current.unsubscribe();
    }
    if (!response.orderRef) {
      return;
    }
    from(cancel(response.orderRef)).pipe(takeUntil(unmount$)).subscribe({
      next: () => setResponse(initialResponse),
      error: log.error
    });
  }, [subscription, unmount$, response.orderRef]);
  const startBankIdProcess = useCallback(() => {
    if (subscription.current) {
      subscription.current.unsubscribe();
    }
    subscription.current = from(authenticate()).pipe(switchMap(({
      autoStartToken,
      qrData,
      orderRef
    }) => {
      setUrl(`bankid:///?autostarttoken=${autoStartToken}&redirect=`);
      setResponse(state => ({
        ...state,
        orderRef,
        qrData
      }));
      return defer(() => collect(orderRef, flowType, signupOrgId, signupEmail, autoSignup)).pipe(repeat({
        delay: DELAY
      }), takeWhile(res => res.status === "pending", true));
    }), takeUntil(unmount$)).subscribe({
      next: (res: CollectResponse) => {
        if (res.status === "pending") {
          setResponse(state => ({
            ...state,
            ...res,
            hintCode: res.hintCode ? res.hintCode : state.hintCode
          }));
        } else if (res.status === "complete") {
          setResponse(state => ({
            ...state,
            ...res,
            qrData: "No Data"
          }));
          postLoginStatus(true);
          onComplete();
        } else if (res.status === "failed") {
          console.log(res);
          setResponse(state => ({
            ...state,
            ...res
          }));
          postLoginStatus(false);
        }
      },
      error: (err: ErrorResponse) => {
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: "BankId Error",
            value: err
          });
        }
        setResponse(state => ({
          ...state,
          ...{
            status: "failed"
          },
          ...err
        }));
      }
    });
  }, [autoSignup, flowType, onComplete, signupEmail, signupOrgId, unmount$]);
  const reset = useCallback(() => {
    setUrl(undefined);
    setResponse(initialResponse);
  }, []);
  return ([startBankIdProcess, response, url, reset, cancelProcess] as const);
}