import _styled from "@emotion/styled";
import { FC, useMemo } from "react";
import { dbtTheme } from "../../configs/dbtTheme";
import { CheckmarkFillDone as CheckmarkFillDone16px, WarningTriangleFill as WarningTriangleFill16px, InfoCircleFill as InfoCircleFill16px, Close as Close16px } from "../icons/16px";
import { CheckmarkFillDone as CheckmarkFillDone24px, WarningTriangleFill as WarningTriangleFill24px, InfoCircleFill as InfoCircleFill24px } from "../icons/24px";
import { Box } from "../box/Box";
interface InfoBoxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: "info" | "error" | "warn" | "success";
  size?: "sm" | "md";
  showIcon?: boolean;
  showCloseIcon?: boolean;
  onCloseIconClick?: () => void;
}
const Container = _styled.div<InfoBoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  border-radius: 12px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.28px;

  .InfoBox__leftSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .InfoBox__iconWrapper {
      display: flex;
      align-items: center;
    }
  }

  .InfoBox__rightSide {
    display: flex;
    cursor: pointer;
  }

  ${({
  size
}) => {
  switch (size) {
    case "sm":
      return `min-height: 44px;`;
    case "md":
      return `min-height: 50px;`;
  }
}}

  ${({
  variant
}) => {
  switch (variant) {
    case "info":
      return `background: ${dbtTheme.colors.status_info_10};
                border: 1px solid ${dbtTheme.colors.status_info_100};
                color: ${dbtTheme.colors.status_info_100};`;
    case "error":
      return `background: ${dbtTheme.colors.status_error_10};
                border: 1px solid ${dbtTheme.colors.status_error_100};
                color: ${dbtTheme.colors.status_error_100};`;
    case "warn":
      return `background: ${dbtTheme.colors.status_warning_100};
                border: 1px solid ${dbtTheme.colors.tertiary02_100};
                color: ${dbtTheme.colors.tertiary02_100};`;
    case "success":
      return `background: ${dbtTheme.colors.status_success_10};
                border: 1px solid ${dbtTheme.colors.status_success_100};
                color: ${dbtTheme.colors.status_success_100};`;
  }
}}
`;
export const InfoBox: FC<InfoBoxProps> = ({
  children,
  showIcon = true,
  variant = "info",
  size = "md",
  showCloseIcon = false,
  onCloseIconClick,
  ...props
}) => {
  const prefixIcon = useMemo(() => {
    switch (variant) {
      case "info":
        return size === "sm" ? <InfoCircleFill16px color={dbtTheme.colors.status_info_100} /> : <InfoCircleFill24px color={dbtTheme.colors.status_info_100} />;
      case "error":
        return size === "sm" ? <WarningTriangleFill16px color={dbtTheme.colors.status_error_100} /> : <WarningTriangleFill24px color={dbtTheme.colors.status_error_100} />;
      case "warn":
        return size === "sm" ? <WarningTriangleFill16px color={dbtTheme.colors.tertiary02_100} /> : <WarningTriangleFill24px color={dbtTheme.colors.tertiary02_100} />;
      case "success":
        return size === "sm" ? <CheckmarkFillDone16px color={dbtTheme.colors.status_success_100} /> : <CheckmarkFillDone24px color={dbtTheme.colors.status_success_100} />;
    }
  }, [size, variant]);
  const closeIcon = useMemo(() => {
    switch (variant) {
      case "info":
        return <Close16px color={dbtTheme.colors.status_info_100} />;
      case "error":
        return <Close16px color={dbtTheme.colors.status_error_100} />;
      case "warn":
        return <Close16px color={dbtTheme.colors.tertiary02_100} />;
      case "success":
        return <Close16px color={dbtTheme.colors.status_success_100} />;
    }
  }, [variant]);
  return <Container className="InfoBox" variant={variant} size={size} {...props}>
      <Box className="InfoBox__leftSide">
        <>
          {showIcon && <Box className="InfoBox__iconWrapper">{prefixIcon}</Box>}
          {children}
        </>
      </Box>

      {showCloseIcon && <Box className="InfoBox__rightSide" onClick={onCloseIconClick}>
          {closeIcon}
        </Box>}
    </Container>;
};