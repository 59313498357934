import _styled from "@emotion/styled";
import React from "react";
import QRCode from "react-qr-code";
const QrContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center"
});
interface QrCodeProps {
  qrData?: string;
  size?: number;
}
export function QrCode(props: QrCodeProps) {
  const {
    qrData = "No Data",
    size = 100
  } = props;
  const fgColor = qrData === "No Data" ? "white" : undefined;
  const bgColor = qrData === "No Data" ? "white" : undefined;
  return <QrContainer>
      <QRCode size={size} value={qrData} fgColor={fgColor} bgColor={bgColor} />
    </QrContainer>;
}