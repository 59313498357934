import { Text } from "@dbt/commons/components/text";
import { Button } from "@dbt/commons/components/button";
import { Box } from "@dbt/commons/components/box";
import { css, cx } from "@emotion/css";
import { InfoBox } from "@dbt/commons/components/infobox";
import { useTranslation } from "next-i18next";
const wrapper = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
const center = css`
  text-align: center;
`;
const header = css`
  margin-bottom: 48px;
`;
const marginBottom = css`
  margin-bottom: 32px;
`;
interface BankIDGeneralErrorProps {
  isIframed: boolean;
  onRetry(): void;
}
export const BankIDGeneralError = ({
  isIframed,
  onRetry
}: BankIDGeneralErrorProps) => {
  const {
    t
  } = useTranslation("BankID");
  return <Box className={cx(wrapper)} data-testid="bankIDGeneralErrorSection">
      {!isIframed ? <Text className={cx(header, center)} as="h3" font={"title_condensed"} data-cy="BankIDGeneralError">
          {t("loginFailed")}
        </Text> : <InfoBox className={cx(marginBottom)} variant="error" data-cy="BankIDGeneralError">
          {t("loginFailed")}
        </InfoBox>}
      <Button variant="primary" color="positive" size="md" className={cx(marginBottom)} onClick={onRetry} data-testid="tryAgainButton">
        {t("tryAgain")}
      </Button>
      <Text className={cx(center)} size={14}>
        {t("appInfo")}
      </Text>
    </Box>;
};