import { AuthResponse } from "pages/api/bankid/authenticate";
import { CancelResponse } from "pages/api/bankid/cancel";
import { CollectResponse } from "pages/api/bankid/collect";
import { buildUrl, getOrThrowResponse, retryFetch } from "@dbt/commons/utils";
import { BankIdFlowType } from "components/bankid/BankID";
export const authenticate = async (): Promise<AuthResponse> => {
  const response = await fetch(`/api/bankid/authenticate`);
  return getOrThrowResponse(response);
};
export const collect = async (orderRef: string, flowType: BankIdFlowType, signupOrgId?: string, signupEmail?: string, autoSignup?: boolean): Promise<CollectResponse> => {
  let url: string;
  if (signupOrgId && signupEmail) {
    url = buildUrl("/api/bankid/collect", {
      orderRef,
      flowType,
      signupEmail,
      signupOrgId,
      autoSignup
    });
  } else {
    url = buildUrl("/api/bankid/collect", {
      orderRef
    });
  }
  const response = await retryFetch(url, 5, 500);
  return getOrThrowResponse(response);
};
export const cancel = async (orderRef: string): Promise<CancelResponse> => {
  const response = await fetch(buildUrl("/api/bankid/cancel", {
    orderRef
  }));
  return getOrThrowResponse(response);
};