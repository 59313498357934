import _styled from "@emotion/styled";
import { Background } from "@dbt/commons/components/background";
import { Panel } from "@dbt/commons/components/panel";
import { BankID } from "./BankID";
import { css, cx } from "@emotion/css";
import { useCurrentBreakpoint, useMobileDevice } from "@dbt/commons/hooks";
import { useEffect, useMemo, useState } from "react";
import { dbtTheme } from "@dbt/commons/configs/dbtTheme";
import { BankIdFlowType } from "components/bankid/BankID";
import { envColorCode } from "@dbt/utils/general";
const panelDesktop = css`
  min-height: 600px;
  width: 581px;
`;
const IframeContainer = _styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${dbtTheme.colors.background.light};
`;
export interface BankIdProps {
  flowType: BankIdFlowType;
  signupEmail?: string;
  signupOrgId?: string;
  autoSignup?: boolean;
}
export default function BankIDPage({
  ...props
}: BankIdProps) {
  const {
    isMobile: isMobileBreakpoint
  } = useCurrentBreakpoint();
  const {
    isMobileDevice
  } = useMobileDevice();
  const envColor = useMemo(() => envColorCode(), []);
  const isMobile = isMobileBreakpoint || isMobileDevice;
  const [isIframed, setIsIframed] = useState(true);
  useEffect(() => {
    if (window) {
      if (window.location !== window.parent.location) {
        setIsIframed(true);
      } else {
        setIsIframed(false);
      }
    }
  }, []);
  if (isIframed) {
    return <IframeContainer>
        <BankID isIframed={isIframed} {...props} />
      </IframeContainer>;
  }
  return <Background envColor={envColor}>
      <Panel className={isMobile ? "" : cx(panelDesktop)}>
        <BankID isIframed={isIframed} {...props} />
      </Panel>
    </Background>;
}